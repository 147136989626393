<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon v-if="activator == 'btn'">
        <v-icon size="36">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("add", { name: "Impuesto" }) }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <Form :tax="tax" @cancel="close" @validated="saveTax" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "NewTaxDialog",
  components: {
    Form: () => import("./Form"),
  },
  data() {
    return {
      tax: {},
      dialog: false,
    };
  },
  props: {
    activator: {
      type: String,
      default: () => "btn",
    },
  },
  methods: {
    ...mapActions("taxes", ["addTax"]),
    saveTax() {
      this.addTax(this.tax).then((response) => {
        this.$alert(this.$t("save_ok"));
        this.close();
        this.$emit("update", response.id);
      });
    },
    close() {
      this.dialog = false;
      this.tax = {};
    },
  },
};
</script>
